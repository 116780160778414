<template>

    <div class="w-full h-full">
        <NavBar/>
        <div class="text-center max-w-screen-lg  
                      items-center justify-center mx-auto p-4">
            <h1 class="text-5xl py-8 font-bold">Cloud Robotics for Education</h1>

            <p class="text-xl leading-relaxed p-4">
                With Scaleup Robotics you can access real robot arms over the
                internet to learn about AI, Robotics & STEM.  No matter if you
                are a high school student interested in learning about how AI is
                transforming the world or an experienced researcher. We let you
                control real robot arms in seconds to learn, run experiments and
                to test your hypotheses about key concepts in AI, science and
                engineering based on real world experimentation! 
            </p>

            <p class="text-xl leading-relaxed pt-8">
            <a href="/waitlist">
            <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg">
                Launching 2023 - Get in touch or book a demo
            </button>
            </a>
            </p>
        </div>

        <div class="text-center max-w-screen-lg flex flex-wrap items-center justify-center mx-auto">
            <div class="flex flex-wrap justify-between mx-auto max-w-screen-xg">
                <img class="p-4 mx-auto lg:w-1/2 rounded-lg drop-shadow-lg" src="/img/example1.png"/>
                <img class="p-4 mx-auto lg:w-1/2 rounded-lg drop-shadow-lg" src="/img/example2.png"/>
            </div>
        </div>
        <div class="text-center max-w-screen-lg flex flex-wrap items-center justify-center mx-auto p-4">
            <h1 class="text-5xl py-8 font-bold">Zero setup time</h1>
            <p class="text-xl leading-relaxed p-4">
                We do it all for you. You just focus on learning, and you can interact with our robots right away.
                Book one robot to experiment, or 100 for your class or company and run interactive sessions right away.
                Users can work with our robots directly from your browser or via our Python API. It could not be simpler.
            </p>
            <img class="flex opacity-100 rounded-lg shadow-lg my-8" src="/img/stream.png"/>
        </div>

        <div class="text-center max-w-screen-lg flex flex-wrap 
                      items-center justify-center mx-auto p-4">
            <h1 class="text-5xl py-8 font-bold">Accessible for all</h1>
            <p class="justify-left text-xl leading-relaxed p-4">
                With Scaleup Robotics, you can experiment with real robots no matter what your skill level is.
                We offer three paradigmns to work with the robots:
                <ul class="text-left text-xl list-disc max-w-screen-sm p-8">
                <li class="py-2">Control robots directly in the browser.  </li>
                <li class="py-2">Use the popular graphical programming library Blockly</li>
                <li class="py-2">Write programs on your own laptop using our Python API</li>
                </ul>
            </p>
            <img class="w-48 h-48" src="/img/blockly.svg"/>
        </div>

        <div class="text-left max-w-screen-lg flex flex-wrap 
                      items-center justify-center mx-auto p-4">
            <h1 class="text-5xl py-8 font-bold">Team</h1>

            <p class="text-xl leading-relaxed text-justify p-4">
                Scaleup Robotics AB was founded with the mission of enabling everyone to participate in the robotics and AI revolution.
                We believe that experimentation, exploration and critical evaluation of AI and Robotics algorithms on real robotic systems 
                as well as the practical exploration of STEM concepts by experimentation with robotic systems 
                is a key pillar for training the next generation of knowledgable yet critical minds for a world where AI and robotics 
                become ubiquitous. We are thankful for KTH Innovation and KTH Holding AB at KTH Royal Institute of Technology, Stockholm, Sweden for all the 
                their support and for the mentoring our team has received during our journey so far.
            </p>
           
<div class="bg-white py-24 sm:py-32 text-left">
  <div class="mx-auto grid max-w-7xl gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-2">
    <ul role="list" class="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
      <li>
        <div class="flex items-center gap-x-6">
          <img class="h-32 w-32 rounded-full" src="/img/florian.png" alt="">
          <div>
            <h3 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Dr. Florian Pokorny</h3>
            <p class="text-sm font-semibold leading-6 text-indigo-600">Co-Founder</p>
            <p class="text-sm leading-6">Associate Prof. Machine Learning at KTH</p>
          </div>
        </div>
      </li>
      <li>
        <div class="flex items-center gap-x-6">
          <img class="h-32 w-32 rounded-full" src="/img/zahid.png" alt="">
          <div>
            <h3 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Muhammad Zahid</h3>
            <p class="text-sm font-semibold leading-6 text-indigo-600">Co-Founder</p>
            <p class="text-sm leading-6">Robotics and Systems expert, previously KTH and American University of Sharjah</p>
          </div>
        </div>
      </li>
      <li>
        <div class="flex items-center gap-x-6">
              <img class="h-30" src="/img/kthi.jpg"/>
        </div>
      </li>
      <!-- More people... -->
    </ul>
  </div>
</div>


        </div>



        <Footer />
    </div>
</template>
<style>
.background { }
</style>
